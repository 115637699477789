/* Checkout page styling */
.checkout {
  .social-login {
    &__divider {
      width: 100%;
      &:before {
        background: $color-light-gray;
        height: 1px;
      }
    }
    &__email-opt-in {
      display: block;
      margin-top: 20px;
      text-align: $ldirection;
      .email-optin {
        text-transform: none;
        font-size: 12px;
        letter-spacing: 0.05em;
      }
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
      p {
        font-size: 12px;
        letter-spacing: 0.05em;
      }
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  .divider {
    display: none;
  }
  .social-login {
    margin: 20px 25px;
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      width: 100%;
      text-align: $ldirection;
      margin-top: 20px;
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
      }
      @media #{$large-up} {
        margin-#{$ldirection}: 0;
        padding: 0;
      }
      .email-optin {
        text-transform: none;
        letter-spacing: 0.05em;
        font-size: 12px;
      }
    }
    &__opt-in-label {
      text-transform: none;
      margin-top: 20px;
      letter-spacing: 0.05em;
      font-size: 13px;
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
      p {
        letter-spacing: 0.05em;
        font-size: 13px;
      }
    }
  }
}
/* Account Profile Page */

.device-pc {
  .social-info {
    padding-top: 0;
    border-top: none;
    padding-#{$ldirection}: 19px;
    @media #{$medium-only} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$large-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 20%;
    }
    &__header {
      font-size: 20px;
      padding-top: 20px;
      @media #{$medium-up} {
        font-size: 30px;
      }
    }
  }
}

.device-mobile {
  .social-info {
    &__header {
      font-size: 20px;
    }
  }
}

@media #{$medium-only} {
  .account-page {
    .sidebar-page {
      &__content {
        padding: 0;
      }
    }
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    @include swap_direction(margin, 0 0);
    @media #{$medium-up} {
      @include swap_direction(margin, 0 100px);
    }
  }
  .social-login {
    &__container {
      padding: 20px;
      text-align: $ldirection;
    }
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      text-align: $ldirection;
      margin-#{$ldirection}: 0;
    }
    .fb-disclaimer-container {
      .fb-disclaimer {
        padding-top: 0;
      }
    }
    .tooltip {
      display: block;
    }
    .email-optin {
      text-transform: none;
      padding-top: 0;
      p {
        font-size: 12px;
        letter-spacing: 0.05em;
        margin-bottom: 0;
      }
    }
    &__terms {
      text-align: $ldirection;
      p {
        font-size: 12px;
        letter-spacing: 0.05em;
      }
    }
  }
}
/* Order confirmation page */
#confirm {
  .pg_wrapper {
    .social-login {
      &__container {
        text-align: $ldirection;
        padding-bottom: 0;
        margin-bottom: 20px;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        font-size: 40px;
        font-family: $optimalight;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
      &__email-opt-in {
        margin-top: 15px;
        margin-#{$ldirection}: 0;
      }
      &__opt-in-label {
        text-transform: none;
        letter-spacing: 0.05em;
        font-size: 14px;
        margin-top: 0;
      }
    }
  }
}
/* Profile preferences page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}
/* Bokk Appointment */
.appointments-container {
  .sign-in-form-container {
    .social-login {
      &__divider {
        width: 100%;
      }
      &__email-opt-in {
        padding-top: 20px;
        text-align: $ldirection;
        a {
          text-transform: none;
          letter-spacing: 0.1em;
          font-size: 12px;
        }
      }
      &__terms {
        text-align: $ldirection;
        padding: 0;
        margin-top: 0;
        p {
          font-size: 12px;
        }
      }
    }
  }
}

.page-wrapper {
  .social-login {
    .tooltip {
      display: block;
    }
  }
}
/* toolltip */
.social-login {
  &__container {
    .tooltip {
      text-transform: none;
      letter-spacing: 0.05em;
      font-size: 15px;
      text-align: $ldirection;
      position: relative;
      display: inline-block;
      width: 100%;
      .tooltiptext-over {
        visibility: hidden;
        width: 100%;
        background-color: $color-dark-gray;
        color: $color-white;
        text-align: justify;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 99;
        left: 0%;
        opacity: 0;
        transition: opacity 0.3s;
        font-weight: normal;
        bottom: 100%;
      }
      a {
        color: $color-white;
      }
      .tooltiptext-over::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 10%;
        border-width: 5px;
        border-style: solid;
        border-color: $color-dark-gray transparent transparent transparent;
      }
      &:hover .tooltiptext-over {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

label.fb-disclaimer {
  text-transform: none;
}
