@media (min-width: 768px) {
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      width: auto;
      height: 40px;
      background-image: url('/media/images/global/sprites-s38a9885fce.png');
      background-position: -7px -3205px;
      #brick__pure-envy & {
        background-color: $color-white;
      }
    }
  }
  .page-header {
    min-height: 85px;
    @include swap_direction(padding, 9px 0 0);
  }
}

.device-pc {
  .discover_more__product-header {
    line-height: 30px;
  }
  .spp-product {
    &__how-to-use {
      .spp__steps {
        overflow: visible;
        .spp-howtouse {
          min-height: 750px;
          position: relative;
          .cta {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            width: 80%;
          }
          .spp_howtouse__header {
            min-height: 220px;
          }
        }
      }
    }
  }
  .welcome15-overlay {
    .email_popover {
      .email_popover__social_icons {
        top: 320px;
      }
    }
  }
}

#waitlist-overlay {
  p {
    @include swap_direction(margin, 5px 0);
  }
  .error_messages {
    word-break: break-word;
  }
  .form-item {
    margin-bottom: 15px;
  }
}

.sumptuous-knockout__parallax-carousel-wrapper {
  #sumptuous-knockout__parallax-carousel1 {
    .sumptuous-knockout_hover-text-container1 {
      #{$rdirection}: auto;
    }
  }
}

.sidebar-page__content {
  .cs-quick-info__block--live-chat {
    margin-#{$ldirection}: 5px;
    margin-#{$rdirection}: 5px;
    table {
      margin-bottom: 0 !important;
      &.lpStaticButton {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
      }
    }
  }
}

.section-store-locator {
  &.device-pc {
    .store-locator {
      .local-search-form {
        .local-search-form__city {
          float: $ldirection;
          width: 195px !important;
        }
      }
      .doors-results__pager {
        .doors-results__pager-pages {
          @include swap_direction(margin, 0 0 0 10px);
          @include swap_direction(padding, 0 0 0 10px);
        }
      }
      .adp-directions {
        width: 100%;
        .adp-substep {
          img {
            top: 0px;
            margin-top: 10px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}

.page-wrapper {
  .results-pagination {
    .view-all {
      padding-#{$rdirection}: 1px;
    }
  }
}

.mpp__product {
  .product_brief__desc1 {
    margin-bottom: 40px;
  }
}

.section-night-experience {
  .page-branding {
    &__logo {
      width: 236px;
      height: 31px;
    }
  }
}

.section-resilience-lift {
  .product-hero-tout {
    .tout__product-copy {
      padding-#{$ldirection}: 35%;
    }
  }
}

.section-revitalizing-supreme,
.section-bronze-goddess,
.section-sumptuous,
.section-double-wear {
  .product-hero-tout {
    .tout__product-copy {
      padding-#{$ldirection}: 55%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 731px) {
  .double-wear-cushion-stick__hovers {
    margin-top: 3% !important;
  }
  .double-wear-cushion-stick__hovers-how-to-LHS {
    margin-top: 80.45% !important;
  }
}

#double-wear-cushion-stick__module-3 {
  .double-wear-cushion-stick {
    &__hovers-text8-show {
      &.double-wear-cushion-stick__hovers-text-show {
        width: 58%;
      }
    }
  }
  #double-wear-cushion-stick {
    &__how-to-text1,
    &__how-to-text3 {
      margin-top: 14%;
    }
    &__how-to-text2,
    &__how-to-text4 {
      margin-top: 10%;
    }
    &__how-to-text5,
    &__how-to-text6,
    &__how-to-text7 {
      letter-spacing: 0.85px;
    }
  }
}

.anr-night-experience__minicarousel_container {
  .anr-night-experience__mini_carousel {
    min-height: 740px;
    position: relative;
    .anr-night-experience__anr_quick_shop_container.darkened {
      position: absolute;
      bottom: 0;
      margin-#{$ldirection}: 51px;
    }
    #anr-night-experience__anr-quick-shop-module9-1 {
      margin-#{$ldirection}: 19%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .product-full__button-container {
    button.product-full {
      &__add-button {
        @include swap_direction(padding, 0 12px);
      }
    }
  }
  .anr-night-experience__minicarousel_container {
    .anr-night-experience__mini_carousel {
      min-height: 580px;
      .anr-night-experience__anr_quick_shop_container.darkened {
        margin-#{$ldirection}: 38px;
      }
    }
  }
}

@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  .anr-night-experience__minicarousel_container {
    .anr-night-experience__mini_carousel {
      min-height: 480px;
      .anr-night-experience__anr_quick_shop_container.darkened {
        margin-#{$ldirection}: 20px;
      }
      #anr-night-experience__anr-quick-shop-module9-3 {
        margin-#{$ldirection}: 8% !important;
      }
      #anr-night-experience__anr-quick-shop-module9-4 {
        margin-#{$ldirection}: 10.2% !important;
      }
    }
  }
}

.anr-night-experience__desc {
  height: 120px;
}

#anr-night-experience__moisturize_desc_1 {
  margin-bottom: 10.8% !important;
}

.night-experience-page {
  .anr-night-experience {
    &__hero-wrapper {
      #anr-night-experience__carousel-2 {
        .anr-night-experience__carousel-text {
          #anr-night-experience {
            &__carousel-2-text5 {
              margin-top: 0.8%;
            }
            &__carousel-2-text7 {
              margin-top: -1%;
            }
          }
        }
      }
    }
    &__module-wrapperBottom {
      #anr-night-experience__module7 {
        .anr-night-experience__carousel2-wrapper {
          #anr-night-experience__carousel2 {
            #anr-night-experience {
              &__carousel2-text26 {
                margin-top: -0.5%;
              }
              &__carousel2-text27 {
                margin-top: -3.4%;
              }
            }
          }
        }
      }
    }
  }
}

.back-to-top {
  width: 78px;
}

#colorbox.colorbox__quickshop {
  .quickshop__description {
    @include swap_direction(padding, 50px 29px 10px 0);
  }
  .quickshop__headers {
    max-height: inherit;
  }
}

body.ie {
  #supreme-power_section-2 {
    .results-disclaimer {
      margin-bottom: 0;
    }
  }
  #supreme-power_section-4 {
    .quick-shop_container {
      .global-aging-creme.quick-shop_btn {
        margin-top: 10px;
      }
    }
  }
}

.page-navigation__supplemental {
  a.slide {
    &__link {
      white-space: normal;
    }
  }
}

#tips_boutique {
  .step {
    .media-container {
      img {
        max-width: none !important;
        position: relative !important;
        width: 100%;
        height: 100%;
      }
    }
  }
  .section-3-minute-beauty & {
    .mpp-carousel {
      .carousel_container {
        height: 85%;
      }
      .carousel {
        li {
          height: 520px;
        }
      }
    }
  }
}

.page-sticky-footer {
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      border-left: none;
    }
  }
}

#mpp__filter-tab {
  display: none;
}

.selectBox-dropdown-menu {
  li {
    a {
      .filter-dark {
        background: #bb9362;
      }
      .filter-light {
        background: #e5ba8f;
      }
    }
  }
}

.multiple-signs-of-aging-page {
  .mpp__header {
    font-family: Optima Display Roman;
  }
}

.mpp__header {
  margin-top: 30px;
}

.product__inv-status-item.coming-soon a.button.cta.button--dark {
  display: none !important;
}
// 2018 Creative refresh
.section-aerin,
#{$aerin} {
  .page-header {
    @media #{$xlarge-up} {
      .page-branding {
        margin-left: 0;
      }
      .page-utilities__search-icon {
        background-image: url('/media/export/cms/gnav/search_blue.png');
        background-position: 0 0;
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
  &.gnav-active,
  &.mobile-gnav-active {
    #{$alt} {
      @media #{$xlarge-up} {
        .page-branding__logo {
          background: url('/media/images/global/elc-logo-small-2x.png') 0 0 no-repeat;
          background-size: 236px 31px;
          width: 236px;
          height: 31px;
        }
        .page-utilities__search-icon {
          background-image: url('/media/export/cms/gnav/search_blue.png');
        }
      }
    }
  }
  #{$search}#{$alt}#{$alt-color-white} {
    .page-utilities__search-icon {
      background-image: url('/media/export/cms/gnav/search_blue.png');
      background-position: 0 0;
      width: 20px;
      height: 20px;
      display: block;
    }
  }
}

.section-re-nutriv,
.brand-renutriv {
  @media #{$xlarge-up} {
    &.gnav-active,
    &.mobile-gnav-active {
      #{$alt} {
        .page-utilities .page-utilities__search-icon {
          background-image: url('/media/export/cms/gnav/search_blue.png');
        }
      }
    }
    #{$search}#{$alt}#{$alt-color-white} {
      .page-utilities__search-icon {
        background-image: url('/media/export/cms/gnav/search_blue.png');
        background-position: 0 0;
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
}

#cboxContent {
  .sign-in-component--loyalty {
    padding-top: 35px;
  }
  .sign-in-component {
    &__form--registration {
      height: auto;
    }
  }
}
.page-footer {
  .node-elc-nodeblock {
    z-index: 99;
  }
}
