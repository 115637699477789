$abp-custom-range: (810px, 1080px);
$abp-custom-screen: '(min-width:#{lower-bound($abp-custom-range)}) and (max-width:#{upper-bound($abp-custom-range)})' !default;

@media #{$large-up} {
  .device-pc.section-book-appointment {
    .page-header {
      min-height: 82px;
    }
  }
}

#appointment-book-container {
  .box-price-time-container {
    margin-top: 5px;
    clear: left;
  }
  .box-price-time {
    position: relative;
    float: left;
    width: 65px;
    height: 30px;
    margin-right: 20px;
    margin-left: -15px;
    margin-bottom: 15px;
    p {
      text-align: center;
      margin-top: 10px;
      color: #000;
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.services {
    .appt-book-page-header-content {
      &__title {
        line-height: 0.9em;
        margin-top: 0.5em;
        margin-bottom: 0.3em;
      }
    }
  }
  .appt-book-content-header {
    .section-header__inner {
      text-transform: uppercase;
    }
  }
  .location-select-section-content__title {
    font-size: 45px;
    line-height: 21px;
    @media #{$large-up} {
      font-size: 60px;
      line-height: 80px;
    }
    @media #{$xxlarge-up} {
      font-size: 90px;
    }
  }
  .location-select-section-content__sub-title {
    font-size: 42px;
    letter-spacing: -0.02em;
    @media #{$large-up} {
      font-size: 60px;
    }
    @media #{$xxlarge-up} {
      font-size: 90px;
    }
  }
  .service-select {
    @media #{$medium-up} {
      .only-time {
        margin-left: -8px;
      }
      .service-select {
        .service {
          .selection-bar {
            padding-top: 5px;
          }
          .service-details {
            .service-title {
              line-height: 1;
              margin-top: 5px;
              margin-bottom: 8px;
            }
          }
        }
      }
      .services-renutrive,
      .services-skincare {
        .service {
          width: 50%;
          .service-details {
            text-align: left;
            width: 40%;
            margin-left: -16%;
            left: 47%;
            .service-title {
              font-family: OptimaDisplayLight;
              font-size: 33px;
              line-height: 1.5em;
              font-weight: bold;
            }
            .selection-bar {
              .selection-selector {
                float: left;
              }
            }
          }
        }
      }
      .services-skincare {
        max-width: 984px;
        margin: auto;
        .service {
          padding: 0 10px;
          .service-image {
            display: block;
          }
          .service-details {
            left: 0;
            margin-left: 3%;
            .service-title {
              margin-bottom: 0px;
              margin-top: 0px;
            }
          }
        }
      }
      .services-renutrive {
        .service {
          padding-left: 50px;
          padding-right: 50px;
          min-height: 250px;
          padding: 0 10px;
          .service-details {
            margin-left: -16%;
          }
        }
      }
    }
    .services-skincare,
    .services-container {
      .service-details {
        .lesson_description {
          font-family: OptimaDisplayLight;
        }
      }
    }
    .services-renutrive {
      .service-details {
        .lesson_description {
          font-family: 'AkzidenzGrotesk W1G';
        }
      }
    }
    .service {
      @media (min-width: 736px) and (max-width: 768px) {
        overflow: visible;
      }
      .service-details {
        @media (min-width: 736px) and (max-width: 768px) {
          top: 0;
          overflow: visible;
        }
        h3.service-title {
          @media #{$medium-down} {
            font-size: 25px !important;
            line-height: 26px !important;
            font-weight: 600;
          }
          @media #{$abp-custom-screen} {
            font-size: 24px;
          }
        }
      }
      img[src=''].service-image {
        @media #{$small-only} {
          width: 0;
          display: none;
          & + .service-details {
            width: 92%;
            margin: 10% 4.5% 0;
            .lesson_description {
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.counters {
    .appt-book-page-header {
      @media #{$small-only} {
        margin-top: 20%;
      }
    }
  }
  .appointments-container {
    @media #{$medium-up} {
      .sign-in-container {
        .sign-in-form-container {
          .login__password {
            width: 48%;
          }
        }
      }
    }
    .selects-container {
      .virtual-location {
        @media #{$medium-up} {
          @media #{$large-down} {
            width: 100% !important;
          }
        }
      }
    }
    .step3 {
      .book-appt-container {
        .appt-book-first-name,
        .appt-book-last-name,
        .appt-book-email,
        .appt-book-mobile,
        .login__password {
          color: #040a2b;
        }
        .appointment_privacy_legal_text {
          span {
            float: left;
            padding-top: 20px;
            font-size: 12px;
            a {
              text-decoration: underline;
            }
          }
        }
      }
      .mobile-phone-fieldset {
        @media #{$small-up} {
          float: right;
        }
        .appt-book-mobile-prefix {
          width: 80px;
          float: left;
          border-right: 0;
        }
        .appt-book-mobile {
          width: calc(100% - 80px);
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .artist {
        display: none;
      }
    }
  }
  .my-appointments {
    .artist {
      display: none;
    }
    .appointments-buttons {
      .appt-book-link {
        white-space: nowrap;
      }
    }
  }
}

@media #{$large-only} {
  .appt-book-overlay {
    &.appt-book-datepicker-overlay {
      .overlay-content-container {
        padding: 50px 40px;
      }
    }
  }
}

.appt-book {
  .service-select {
    .service {
      .service-details {
        h3 {
          &.service-title {
            line-height: normal;
          }
        }
        .selection-time {
          display: none !important;
        }
      }
    }
  }
}
