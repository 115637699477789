.loyalty_popover {
  &__checkbox-container {
    @media #{$medium-up} {
      width: 100%;
    }
  }
  .info-link-gdpr {
    margin-top: 10px;
  }
}

.loyalty_popover__input {
  .form-text,
  .form-password,
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    @media #{$medium-up} {
      width: 298px;
    }
  }
  .form-submit {
    @media #{$medium-up} {
      width: 146px;
    }
  }
}

.loyalty-offer-overlay {
  .loyalty_popover__header {
    font-size: 38px;
    @media #{$large-up} {
      font-size: 74px;
    }
  }
}

.page-header {
  .page-utilities__account {
    .user-loyalty-state {
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      text-align: $rdirection;
      text-transform: none;
      #{$rdirection}: 20px;
      min-width: 375px;
      bottom: 0;
      z-index: 9;
    }
    .user-login-state {
      .page-utilities__signin-text {
        cursor: pointer;
      }
    }
  }
}

.page-footer {
  &.is-alternate {
    .my-feed-tray--loyalty {
      .my-feed-loyalty {
        &__header {
          .first-name {
            padding-#{$rdirection}: 5px;
          }
        }
      }
      .my-feed-tray__inner {
        .my-feed-summary,
        .special-offer {
          display: none;
        }
        .my-feed-tray__dual-col {
          width: 100%;
        }
        .my-feed-loyalty__joined {
          a.my-feed-loyalty__account {
            bottom: inherit;
          }
        }
      }
    }
  }
}

#cboxLoadedContent {
  .social-login {
    &__container {
      @media #{$medium-up} {
        .fb-disclaimer-container {
          .fb-disclaimer {
            p {
              font-size: 12px;
              line-height: initial;
            }
          }
        }
      }
    }
    &__email-opt-in {
      .email-optin {
        p {
          margin-top: 0;
          line-height: initial;
          padding-top: 3px;
        }
      }
    }
    &.gnav {
      @include swap_direction(margin, 0 auto);
      @media #{$large-up} {
        @include swap_direction(margin, 0 80px 0 0);
      }
    }
  }
}

#loyalty__content {
  #loyalty__page__index {
    .loyalty__panel__offers {
      &__offer-wrapper {
        width: 160px;
        min-height: 560px;
        @media #{$medium-up} {
          min-height: 456px;
        }
      }
      &__sku__cta__container {
        padding-bottom: 0;
        .loyalty__panel__product__button {
          visibility: visible;
        }
      }
      &__button-container {
        .loyalty__panel__offers__button {
          display: block;
          visibility: visible;
        }
      }
      &__cta {
        padding-bottom: 0;
      }
      &__sku__selected,
      &__selected {
        display: block;
      }
      &__remove,
      &__sku__remove {
        margin-bottom: 30px;
      }
      &__offer-image {
        width: 100%;
      }
    }
    #loyalty__panel__offers__sku {
      .loyalty__panel__offers__sku__product {
        height: 520px;
      }
    }
  }
  .e-list_not_signed-right {
    &__text {
      display: block;
    }
    .btn_join {
      @include swap_direction(padding, 0 20px);
      background: $color-navy;
      color: $color-white;
    }
  }
  .e-list_not_signed-left__inner {
    @media #{$medium-up} {
      background: url('/media/images/account/loyalty/loyalty_tout_bg02.jpg') no-repeat $rdirection
          bottom,
        url('/media/export/cms_2.0/loyalty/signin/join_e_list_header_it.png') no-repeat $ldirection
          top;
    }
  }
  section#loyalty__panel__notification.is-user.is-member {
    @media #{$medium-up} {
      background: url('/media/loyalty/account-page-loyal-pc-it-banner.png') no-repeat;
    }
  }
  .account-e-list {
    .e-list_not_signed_wrapper {
      .e-list_not_signed-left {
        @media #{$xxlarge-up} {
          width: 57%;
        }
      }
      .e-list_not_signed-right {
        &__text {
          @media #{$large-up} {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.page-sticky-footer {
  .special-offers-drawer {
    .drawer-formatter__trigger {
      font-weight: normal;
    }
  }
  .my-feed-drawer {
    .drawer-formatter__trigger {
      text-transform: none;
    }
  }
  .page-sticky-footer__inner {
    height: auto;
    @media #{$medium-up} {
      height: $sticky-footer-height;
    }
  }
  &__loyalty {
    &.page-sticky-footer {
      &__loyalty--join {
        &__link {
          @media #{$medium-up} {
            height: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 300px;
            display: inline-block;
          }
        }
      }
    }
  }
}

#checkout-sidebar {
  .order-summary-panel {
    .loyalty-points-description__panel {
      @include swap_direction(padding, 10px 24px);
    }
  }
}

.loyalty_offer_module {
  .checkout__offer-code {
    &__wallet-item__image {
      @include swap_direction(margin, auto);
    }
    &__wallet-item__inner {
      text-align: center;
    }
    &__wallet-item__header {
      font-family: $optimaregular;
      font-size: 22px;
    }
    &__wallet-item__description {
      font-family: $optimaregular;
      font-size: 16px;
      line-height: 20px;
    }
    &__wallet-remove,
    &__wallet-selected {
      display: none;
    }
    &__wallet-item {
      &.is-applied {
        .checkout__offer-code {
          &__wallet-apply {
            display: none;
          }
          &__wallet-remove {
            @include swap_direction(margin, 20px auto);
            display: block;
            width: 160px;
          }
        }
      }
      &.offer-code-wallet__itemis-applied {
        .checkout__offer-code {
          &__wallet-apply {
            display: none;
          }
          &__wallet-remove {
            @include swap_direction(margin, 20px auto);
            display: block;
            width: 160px;
          }
        }
      }
    }
  }
  .slick-slider {
    .slick-prev {
      #{$ldirection}: 0;
      z-index: 2;
    }
    .slick-next {
      #{$rdirection}: 0;
      z-index: 2;
    }
  }
}

.co_updates_enabled {
  .page-wrapper {
    #confirmation-page {
      .checkout-page-title {
        @media #{$medium-up} {
          text-align: $ldirection;
        }
      }
      .top-banner {
        .loyalty-find-out-more {
          text-decoration: underline;
        }
        .third-header {
          text-align: $ldirection;
        }
      }
    }
  }
}

.loyalty_mrkt_ldng {
  .loyalty {
    &_points {
      width: 100%;
      @media #{$large-up} {
        width: 720px;
      }
    }
    &_tiers {
      .tier {
        .fouth_heading {
          font-size: 54px;
          @media #{$large-up} {
            font-size: 66px;
          }
        }
      }
    }
  }
  .loyalty_question {
    a {
      color: $color-darker-gray;
    }
  }
}

.loyalty-account-page__about {
  &:before {
    width: 100%;
    height: 100%;
    #{$ldirection}: 0;
    margin-#{$ldirection}: 0;
  }
}

.device-mobile {
  .checkout {
    #payment-other-terms,
    .loyalty-panel {
      @include swap_direction(padding, 0 24px);
      .loyalty-join__subtitle {
        @include swap_direction(padding, 0 0 6px !important);
      }
    }
    #checkout_complete {
      order: 4;
    }
    .loyalty-points-description {
      &__panel {
        padding: 0 25px;
        font-weight: bold;
      }
    }
  }
}

#loyalty__panel__benefits {
  .loyalty__panel__benefits {
    &__container {
      display: block;
    }
    &__tier {
      width: 100%;
      @media #{$medium-up} {
        width: 33.333%;
      }
    }
  }
}

.elist-ldng-gdpr {
  padding: 8px 0;
  text-align: #{$ldirection};
  text-transform: none;
  font-weight: normal;
}

.elc-user-state-anonymous {
  .elist-ldng-gdpr {
    &.not_loyalty_join_now {
      display: none;
    }
  }
}

.e-list_not_signed-right__button-container {
  .info-link-gdpr {
    padding: 8px 2px;
  }
}

.loyalty-account-page {
  .loyalty-body {
    .slick-dots {
      display: none;
    }
  }
  .loyalty__panel__about__landing {
    .loyalty_mrkt_ldng {
      .elist-banner {
        .elist {
          &-banner-content {
            @media #{$medium-up} {
              width: 48%;
            }
            @media #{$xxlarge-up} {
              width: 44%;
            }
          }
        }
      }
    }
  }
}

.sign-in-component {
  &--loyalty {
    .sign-in-component {
      &__form-loyalty-header {
        font-size: 36px;
      }
    }
  }
}

.account-utilities {
  .sidebar-menu {
    .sidebar-menu {
      &__link {
        &.active {
          font-weight: normal;
          color: $color-darker-gray;
        }
      }
    }
  }
  .account-utilities {
    &__account-nav {
      .account-utilities {
        &__link {
          &.active {
            font-weight: normal;
            color: $color-darker-gray;
          }
        }
      }
    }
  }
}

#index,
#landing {
  .not_loyalty_member {
    #loyalty__content {
      .account-e-list {
        .e-list_not_signed-right {
          p.footnote {
            display: block;
          }
          &__button-container .info-link-gdpr {
            padding: 0;
          }
        }
      }
    }
  }
  @media #{$large-up} {
    #loyalty__content {
      .e-list_not_signed {
        &__link-container {
          margin-top: 0px;
        }
        &_wrapper {
          .e-list_not_signed {
            &-left {
              &__inner .title_main {
                font-size: 30px;
                padding-top: 10px;
              }
            }
          }
        }
        &-right {
          margin-top: -260px;
        }
      }
    }
  }
  @media #{$medium-up} {
    #loyalty__content {
      .e-list_not_signed {
        .sub_title {
          letter-spacing: 0.08em;
        }
        &__link-container {
          margin-top: 236px;
        }
      }
      &__link-container {
        margin-top: 0;
      }
    }
  }
}

@media #{$medium-down} {
  #loyalty__content {
    .e-list_not_signed_wrapper {
      .e-list_not_signed-right {
        margin-top: -300px;
      }
      .error {
        margin-top: 30px;
      }
    }
    .e-list_not_signed__link-container {
      margin-top: 310px;
    }
  }
}

.page-node {
  #colorbox.loyalty-offer-overlay {
    height: 550px !important;
    #cboxWrapper {
      height: 550px !important;
      #cboxMiddleLeft {
        height: 550px !important;
      }
      #cboxContent {
        height: 550px !important;
        .loyalty_popover {
          .loyalty_popover__header {
            padding-top: 40px;
          }
        }
        .password-field__info {
          .password-field__rules {
            li {
              color: $color-navy;
            }
          }
        }
      }
    }
  }
}

#index.device-pc {
  .account-body {
    .account-order {
      margin-top: 20px;
    }
  }
}

.account-mobile-landing-page {
  .account-page__loyalty.not_loyalty_member {
    margin-bottom: 50px;
  }
}

.page-wrapper {
  .loyalty_mrkt_ldng {
    .elist-banner {
      .elist {
        &-banner-content {
          @media #{$medium-up} {
            width: 43%;
          }
          @media #{$xxlarge-up} {
            width: 44%;
          }
        }
      }
    }
  }
}
