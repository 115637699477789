#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Utile (';
              }
              &:after {
                content: ')';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'No (';
              }
              &:after {
                content: ')';
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='quantiannihai'] {
          &::before {
            content: 'FILTRO:';
            font-size: 11px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 63%;
          margin-top: 0;
          .pr-multiselect-button {
            span {
              font-size: 11px;
            }
            &-daquantotempousiiprodottiestéelauder {
              display: none;
            }
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              font-size: 11px;
              content: 'Ordina: ';
            }
            .pr-rd-sort {
              font-size: 11px;
              background-size: 21px 43px;
            }
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='lauder'] {
          display: none;
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 60px;
            .pr-search-icon {
              &::after {
                content: 'PARTIRE';
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 70px;
          }
        }
        .pr-rd-main-header-search {
          width: 35%;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        padding-bottom: 30px;
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                top: 63px;
              }
            }
          }
          .pr-snippet-rating-decimal {
            &:after {
              content: ' di 5';
            }
          }
        }
      }
    }
  }
}

.mpp__product {
  .product_brief__desc1 {
    margin-bottom: 0;
  }
}
