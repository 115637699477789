#loyalty__panel__transactions td {
  border: none;
  border-bottom: 1px solid $color-light-gray;
}

#loyalty__content {
  color: $color-navy;
  .chart {
    display: none;
    p {
      color: $color-navy;
      font-size: 15px;
    }
  }
  .points_to_next_earn_before {
    display: none;
    @media #{$medium-up} {
      display: block;
      clear: both;
      padding-top: 25px;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
    }
    a {
      display: none;
      @media #{$medium-up} {
        display: block;
        text-transform: uppercase;
        margin-top: 10px;
      }
    }
  }
  #loyalty__panel__transactions {
    padding: 0px 20px;
    @media #{$medium-up} {
      margin-bottom: 30px;
    }
    .loyalty__panel__transactions {
      &__current-tier,
      &__available-amount {
        display: none;
      }
      &__list__column-activity {
        display: table-cell;
        &__points-earned {
          display: block;
        }
      }
      &__title {
        border-bottom: 0;
        border-top: 1px solid $color-light-gray;
        padding-top: 15px;
        margin-bottom: 0;
        @media #{$medium-up} {
          border-top: 0;
          border-bottom: 1px solid $color-light-gray;
        }
      }
      &__table {
        margin-bottom: 20px;
        @media #{$medium-up} {
          margin-bottom: 0;
        }
        th {
          font-size: 12px;
          text-align: #{$ldirection};
          border-bottom: 1px solid $color-light-gray;
          letter-spacing: 0.15em;
          display: table-cell;
        }
        .loyalty__panel__transactions {
          &__list__column-total,
          &__list__column-plus {
            display: none;
          }
          &__list__column-earned {
            display: none;
            @media #{$medium-up} {
              width: auto;
              display: table-cell;
            }
          }
        }
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 50px;
        bottom: 15px;
        background: url('/media/loyalty/sprites-icons.png');
        background-position: 0px -2957px;
        &.open {
          background-position: 0px -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
  #loyalty__panel__benefits {
    padding: 0px 20px;
    .loyalty_points {
      display: none;
    }
    .loyalty__panel__benefits {
      &__description {
        @include swap_direction(padding, 0px 0px 25px);
        background: $color-white;
        min-height: auto;
        border-bottom: 1px solid $color-navy;
        @media #{$medium-up} {
          border-bottom: 0;
        }
        &-list {
          line-height: 1.6;
          ul {
            padding-#{$rdirection}: 20px;
          }
          li {
            list-style: square;
            line-height: 1.6;
            padding: 0;
          }
          .loyalty__panel__benefits__unlock {
            display: none;
          }
        }
        &-list__overlay {
          display: none;
          .loyalty__panel__benefits__unlock {
            padding: 0;
          }
        }
      }
      &__container {
        display: block;
        margin-top: 0px;
      }
      &__tier {
        width: 100%;
        opacity: 1;
        text-align: #{$ldirection};
        @media #{$xlarge-up} {
          width: 33.3%;
          padding-#{$rdirection}: 40px;
        }
        &-level {
          text-transform: uppercase;
          padding: 10px 0px;
          color: $color-navy;
          font-family: $font-bauerbodoni-roman;
          letter-spacing: 0.02em;
          @media #{$medium-up} {
            @include swap_direction(padding, 0px 0px 8px);
            border-bottom: 1px solid $color-navy;
            margin-bottom: 18px;
          }
        }
        &-header {
          padding-bottom: 5px;
          &.default_tier,
          &.on_hover {
            background: none;
            padding-bottom: 5px;
          }
        }
        &.next_tier {
          opacity: 1;
        }
        &--1 {
          ul {
            padding-#{$rdirection}: 20px;
          }
        }
        &--3 {
          .loyalty__panel__benefits__description {
            border-bottom: 0;
          }
        }
      }
      &__title {
        padding-top: 20px;
        border-top: 1px solid $color-light-gray;
        border-bottom: 0;
        @media #{$medium-up} {
          border-top: 0;
          border-bottom: 1px solid $color-light-gray;
        }
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 50px;
        bottom: 15px;
        background: url('/media/loyalty/sprites-icons.png');
        background-position: 0px -2957px;
        &.open {
          background-position: 0px -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
  .loyalty-landing-menu {
    display: none;
  }
  #loyalty__page__index {
    background-image: none;
    margin-top: 0;
    padding-top: 0;
    .account-breadcrumb-wrapper {
      top: 35vw;
    }
    .loyalty__page__index {
      &__breadcrumb {
        padding: 0;
        height: 0;
      }
    }
  }
  .e-list_not_signed {
    margin-top: 0;
    .e-list_not_signed-left__inner {
      .title_main {
        visibility: visible;
        font-size: 25px;
        color: $color-navy;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.01em;
        line-height: 25px;
        text-transform: uppercase;
        display: block;
        @media #{$medium-up} {
          padding-top: 25px;
          @if $loyalty-banner-cr23update {
            margin: 10px 0 5px;
          }
        }
        @media #{$xxlarge-up} {
          font-size: 40px;
          padding-top: 20px;
          line-height: 1.2;
          @if $loyalty-banner-cr23update {
            font-size: 30px;
            padding-top: 10px;
            margin: 0;
          }
        }
      }
    }
    .title_super {
      display: none;
    }
    .error {
      @if $loyalty-banner-cr23update {
        clear: both;
        @media #{$xxlarge-up} {
          margin-top: 15px;
        }
      }
    }
    .title_subheader {
      visibility: visible;
      font-weight: normal;
      font-size: 1.1vw;
      line-height: 1.5vw;
      letter-spacing: 0.15em;
      @media #{$xxlarge-up} {
        font-size: 15px;
        line-height: 1.6;
        @if $loyalty-banner-cr23update {
          margin-#{$ldirection}: 0;
          font-size: 14px;
        }
      }
    }
    .sub_title {
      font-size: 16px;
      font-family: $akzidenz;
      line-height: 1.3;
      width: 100%;
      letter-spacing: 0.15em;
      padding-top: 20px;
      text-transform: uppercase;
      @media #{$medium-up} {
        font-weight: bold;
        font-size: 0.88vw;
        line-height: 2vw;
        padding-top: 8px;
        letter-spacing: 0.15em;
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.2;
      }
    }
    .e-list_not_signed__text {
      display: block;
      font-size: 16px;
      width: 100%;
      line-height: 1.2;
      margin-top: 0;
      @media #{$medium-up} {
        margin: 0 auto;
        font-size: 1.1vw;
        line-height: 1.5vw;
        @if $loyalty-banner-cr23update {
          margin: 0;
        }
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.6;
        width: 77%;
      }
    }
    .e-list_not_signed-right__text {
      display: block;
      font-size: 12px;
      line-height: 1.2;
      margin: 0px;
      padding-bottom: 15px;
      text-transform: initial;
      @media #{$medium-up} {
        margin: 5px 0px 10px;
        font-size: 1.1vw;
        line-height: 1.2vw;
        padding: 0;
        letter-spacing: 0.05em;
        @if $loyalty-banner-cr23update {
          margin: 10px 0;
        }
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.6;
      }
      a {
        text-decoration: underline;
      }
    }
    .btn_join {
      color: $color-white;
      background: $color-navy;
      border: 0;
      padding: 20px;
      font-size: 16px;
      height: auto;
      line-height: 15px;
      margin: 10px 0px;
      @media #{$medium-up} {
        padding: 0vw 1vw;
        font-size: 0.9vw;
        height: 3vw;
        line-height: 3vw;
        margin: 0.3vw;
      }
      @media #{$xxlarge-up} {
        padding: 0px 13px;
        font-size: 12px;
        height: 40px;
        line-height: 3;
        margin: 4px;
      }
    }
    &-right {
      .footnote {
        display: none;
      }
      &__button-container {
        padding-top: 0.7vw;
        @media #{$xxlarge-up} {
          padding-top: 10px;
        }
        .loading {
          background-color: transparent;
        }
      }
    }
    &__link {
      font-size: 15px;
      @media #{$medium-up} {
        font-size: 0.8vw;
        line-height: 1.5vw;
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.6;
      }
    }
  }
  .e-list_not_signed_wrapper {
    background-color: transparent;
    text-align: center;
    @media #{$xxlarge-up} {
      background: url('/media/loyalty/account-page-non-pc.jpg') no-repeat;
      backround-size: contain;
      height: 31vw;
      @media #{$xxlarge-up} {
        height: 411px;
        max-width: 1052px;
        padding-right: 25px;
      }
      @if $loyalty-banner-cr23update {
        background: url('#{$elist-banner-account-index-non-member}') no-repeat;
        background-size: cover;
        display: inline-block;
        width: 100%;
      }
    }
    .info-link-gdpr {
      @if $loyalty-banner-cr23update {
        @media #{$medium-up} {
          @include swap_direction(margin, 20px 0 25px 30px);
        }
        @media #{$xxlarge-up} {
          @include swap_direction(margin, 20px 0 62px 30px);
        }
      }
    }
    .e-list_not_signed-left {
      @media #{$medium-up} {
        background: none;
        padding: 0;
        width: 70%;
        margin-left: 19%;
        @if $loyalty-banner-cr23update {
          width: 60%;
          float: none;
          position: static;
        }
      }
      @media #{$xxlarge-up} {
        width: 45%;
        margin: 0;
        margin-#{$ldirection}: 55%;
        float: none;
        position: static;
        @if $loyalty-banner-cr23update {
          margin-#{$ldirection}: 50px;
          text-align: #{$ldirection};
        }
      }
      &__inner {
        @media #{$medium-up} {
          background: none;
          min-height: auto;
          padding-bottom: 0;
        }
      }
    }
    .e-list_not_signed-right {
      width: 100%;
      @media #{$medium-up} {
        margin: 0;
        padding: 0;
        @if $loyalty-banner-cr23update {
          float: none;
          margin-#{$ldirection} : 19%;
          width: 65%;
        }
      }
      @media #{$xxlarge-up} {
        @include swap_direction(margin, 0% 0% 0% 58%);
        background: none;
        float: none;
        width: 40%;
        padding: 0;
        position: static;
        @if $loyalty-banner-cr23update {
          margin-#{$ldirection}: 50px;
          text-align: #{$ldirection};
          width: 38%;
        }
      }
    }
    #label-site_sms_promo_checkbox {
      display: none;
    }
    #label-site_pc_promo_checkbox {
      margin: 0 auto;
      display: block;
      width: 120px;
      padding-bottom: 15px;
      @media #{$medium-up} {
        width: 80px;
        padding-bottom: 0;
      }
      @media #{$medium-only} {
        font-size: 1.1vw;
        padding-top: 6px;
      }
      @if $loyalty-banner-cr23update {
        margin: 0;
      }
    }
  }
  .e-list_signed-left {
    padding: 10px 0px;
  }
  .info-tier-status-3 {
    .new_points {
      &_disclaimer {
        display: none;
      }
    }
  }
}
/* NON LOYALTY */
#loyalty__panel__points {
  .disclaimer {
    display: block;
  }
  &.account-e-list {
    padding: 0;
    clear: both;
    &.is-user {
      &.is-member {
        padding: 0px 25px;
        @media #{$medium-up} {
          padding: 0px 20px;
        }
        .disclaimer {
          display: none;
        }
        .loyalty__panel__points__title--loyalty {
          width: 100%;
          margin: 0;
          text-indent: 0;
          padding: 15px 0px;
        }
      }
    }
  }
}

#loyalty__page__tout {
  #loyalty__panel__points {
    padding: 0px 25px;
    @media #{$medium-up} {
      padding: 0;
    }
    .loyalty__panel__points__title--loyalty {
      border-bottom: 1px solid $color-light-gray;
      padding-bottom: 5px;
      display: block;
      margin: 0;
      text-indent: 0;
      width: 100%;
      @media #{$medium-up} {
        padding: 0px 0px 5px;
        margin-top: 80px;
        border-bottom: 0;
      }
    }
  }
}

#index {
  margin-#{$ldirection}: 0;
  width: 100%;
  &.elc-user-state-loyalty {
    .page-navigation__menu-toggle,
    .page-utilities__search-button,
    .page-utilities.clearfix {
      display: inline-block;
    }
  }
}

#loyalty__panel__offers {
  padding-#{$ldirection}: 20px;
  .loyalty__panel__offers {
    &__offer-wrapper {
      width: 140px;
      display: flex;
      flex-direction: column;
      margin: inherit;
      @media #{$medium-up} {
        width: 230px;
        text-align: inherit;
        min-height: 420px;
      }
    }
    &__select {
      padding-top: 10px;
    }
    &__offer-header {
      font-family: $akzidenz;
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.15em;
      text-align: center;
      padding-top: 15px;
      order: 3;
    }
    &__button-container {
      text-align: center;
    }
    &__offer-image-container {
      width: 140px;
      margin: 0 auto;
      order: 1;
      @media #{$medium-up} {
        width: 144px;
      }
      .checkout__offer-code__wallet-item__image {
        width: 120px;
        height: 198px;
        background: $color-navy;
        &__title {
          color: $color-white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 198px;
          font-size: 20px;
          font-family: $font-bauerbodoni-roman;
          text-transform: uppercase;
          line-height: 1.1;
          letter-spacing: 0.02em;
          padding: 0px 5px;
          word-break: break-word;
          margin-top: 0;
          @media #{$medium-up} {
            font-size: 18px;
          }
        }
        @media #{$medium-up} {
          width: 144px;
        }
      }
    }
    &__list {
      .slick-prev {
        #{$ldirection}: 0;
        @media #{$medium-up} {
          #{$ldirection}: 20px;
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        @media #{$medium-up} {
          #{$rdirection}: 20px;
        }
      }
      .slick-dots {
        display: none;
      }
    }
    &__title {
      margin: 0;
      width: 100%;
      text-indent: 0;
    }
    &__button {
      color: $color-white;
      background-color: $color-navy;
    }
    &__offer-description {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.05em;
      font-family: $akzidenz;
      text-align: center;
      padding-top: 15px;
      order: 2;
      margin: 0 auto;
      @media #{$medium-down} {
        width: 140px;
      }
    }
  }
}

.not_loyalty_member {
  #loyalty__content {
    .account-e-list {
      background: none;
      .e-list_not_signed {
        &_mobile_banner {
          background: url('/media/loyalty/account-page-non-mobile.jpg');
          height: 43vw;
          background-size: cover;
          @media #{$medium-up} {
            display: none;
          }
        }
        &-right {
          &__button-container {
            padding: 0px 40px;
            @media #{$medium-up} {
              @if $loyalty-banner-cr23update {
                padding-#{$ldirection}: 0;
              }
            }
            .info-link-gdpr {
              font-size: 11px;
              @if $loyalty-banner-cr23update {
                @media #{$medium-up} {
                  margin-top: 20px;
                }
              }
            }
          }
          .footnote {
            display: none;
          }
        }
      }
    }
  }
}

.account-body {
  &.sidebar-page__content {
    .account-page__header {
      margin-bottom: 0;
    }
  }
}

.account-page__loyalty {
  #loyalty__panel__points {
    .loyalty__panel__points__cta {
      display: block;
      padding: 0px 25px;
      margin: 25px 0px;
      @media #{$medium-down} {
        a {
          height: inherit;
          line-height: inherit;
        }
      }
    }
  }
}

.account-wishlist {
  .section-head {
    @media #{$medium-up} {
      padding-bottom: 15px;
    }
  }
}

#loyalty__panel__offers__sku {
  padding-#{$ldirection}: 20px;
  .loyalty__panel__offers__sku__list-border {
    padding-top: 40px;
  }
  .button {
    &--disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}

.account-utilities {
  .account-utilities {
    &__welcome-msg {
      letter-spacing: 0.05em;
    }
  }
  .loyalty-faq,
  .loyalty-terms {
    display: none;
  }
}

.loyalty-account-page__about {
  &:before {
    width: auto;
  }
  .loyalty_mrkt_ldng {
    .loyalty_tiers {
      padding: 0;
      .loyalty-catgory-name {
        &.final-catgory {
          @media #{$medium-up} {
            font-size: 24px;
            width: 145px;
          }
          @media #{$large-up} {
            font-size: 22px;
            width: 85%;
          }
        }
        @media #{$medium-up} {
          font-size: 24px;
          width: 160px;
          letter-spacing: 0.05em;
        }
        @media #{$large-up} {
          font-size: 22px;
          width: 85%;
        }
      }
    }
    .elist-banner {
      height: 25vw;
    }
    .beauity-header {
      font-size: 40px;
    }
    .loyalty_points {
      @media #{$medium-up} {
        width: auto;
        padding: 0;
      }
      @media #{$large-up} {
        width: 700px;
        padding: 30px;
      }
    }
  }
}

.registration-page__content {
  .subheadline--section {
    font-size: 12px;
  }
  .email-promotions__content {
    .text--checkbox-label {
      display: none;
    }
  }
}

.loyalty__content-container {
  .is-selected {
    .loyalty__panel__offers__sku__remove,
    .loyalty__panel__offers__button.button--offers,
    .loyalty__panel__product__button.button--offers {
      visibility: visible;
    }
  }
  #loyalty__panel__offers__sku {
    .loyalty__panel__offers__sku__product {
      height: 470px;
    }
  }
}

#loyalty__panel__notification {
  &.is-user {
    &.is-member {
      background: url('/media/loyalty/account-page-loyal-mobile.jpg');
      height: 47vw;
      background-size: cover;
      @if $loyalty-banner-cr23update {
        background: url('/media/loyalty/account-page-loyal-mobile-banner.jpg') no-repeat;
        background-size: contain;
      }
      @media #{$medium-up} {
        background: url('/media/loyalty/Status-Page-Member-DE.jpg') no-repeat;
        [lang='fr-CH'] & {
          background: url('/media/loyalty/Status-Page-Member-FR.jpg') no-repeat;
        }
        [lang='tr-TR'] & {
          background: url('/media/loyalty/Status-Page-Member-TR.jpg') no-repeat;
        }
        background-size: contain;
        height: 26vw;
        @if $loyalty-banner-cr23update {
          background: url('/media/loyalty/account-page-loyal-pc-de-banner.png') no-repeat;
          [lang='tr-TR'] & {
            background: url('/media/loyalty/account-page-loyal-pc-tr-banner.png') no-repeat;
            background-size: cover;
            margin-top: 10px;
          }
          background-size: cover;
        }
      }
      @media #{$xxlarge-up} {
        max-width: 1366px;
        height: 360px;
        @if $loyalty-banner-cr23update {
          height: 411px;
        }
      }
    }
  }
}
