.enhanced_delivery_page {
  &#payment {
    .cc-header {
      display: none;
    }
    #review-address-info {
      .transaction-details {
        dt {
          padding-bottom: 0;
        }
        .transaction-content {
          margin: 0;
          dt h3.checkout__subtitle {
            width: 100% !important;
          }
        }
      }
    }
  }
  .page-wrapper {
    .pg_wrapper {
      .address_form_container {
        .state_container {
          label {
            display: none;
          }
        }
      }
      .checkout_container {
        .checkout__content {
          #address-section-container {
            #billing_address_form_container {
              .state_container {
                &::before {
                  #{$rdirection}: 15px;
                  border: 5px solid transparent;
                  border-top-color: $color-navy;
                  content: '';
                  pointer-events: none;
                  position: absolute;
                  top: 25px;
                }
              }
            }
            .billing-address-form-container,
            .shipping-address-form-container {
              .state_container {
                .field {
                  padding: 0 15px;
                }
              }
            }
            #bill_to_shipping_container {
              .bill-to-ship {
                input[type='checkbox'] {
                  & ~ label {
                    margin-bottom: 42px;
                  }
                }
              }
            }
          }
          .checkout-page-title {
            font-size: 40px;
            line-height: 0.8;
            margin: 40px 0 16px;
            text-transform: capitalize;
            text-align: center;
          }
        }
        .checkout__sidebar {
          .payment-panel {
            #review-address-info {
              .transaction-item {
                &.offer-code-message-section {
                  display: block;
                }
                &.promocode-container,
                &.offer-code-message-section:last-child {
                  display: none;
                }
              }
            }
          }
        }
        .checkout-page-title {
          border-bottom: 0;
          color: $color-navy;
          display: block;
          font-family: $optimaregular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0.25px;
          line-height: 1.25;
          margin: 0;
          padding: 0 0 6px;
          text-align: $ldirection;
          text-transform: uppercase;
          .edit-cart {
            background-image: url('/media/images/checkout/edit_icon.png');
            background-size: contain;
            float: $rdirection;
            height: 20px;
            position: relative;
            width: 20px;
          }
        }
      }
    }
  }
}
