$sign-in-overlay-form-width: 245px;

.sign-in-component {
  min-height: auto;
  @media #{$medium-up} {
    @include clearfix;
    min-height: 420px;
  }
  &.sign-in {
    &.signin-join-loyalty {
      .sign-in-component {
        &__form--sign-in {
          display: none;
        }
      }
    }
  }
  .sign-in-component {
    &__confirm--registration,
    &__confirm--join-loyalty,
    &__sms-opt-in {
      display: none;
    }
    &__sub-header {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.15em;
    }
    &__form {
      .sign-in-component {
        &__header {
          text-transform: none;
          @media #{$medium-up} {
            width: auto;
          }
        }
      }
    }
    &__confirm--registration {
      .sign-in-component__header {
        @media #{$medium-up} {
          @include swap_direction(padding, 0 110px);
          margin-bottom: 43px;
        }
      }
      .sign-in-component__close {
        @include swap_direction(margin, 0 0 15px);
      }
      @media #{$medium-up} {
        .sign-in-component__confirm-options {
          width: $sign-in-form-registration-confirmation-width;
        }
        .sign-in-component__close {
          @include swap_direction(padding, 8px 0 0 58px);
          width: 50%;
        }
        .button {
          @include swap_direction(margin, 0 0 0 60px);
        }
      }
    }
  }
  &.signin-join-loyalty {
    .sign-in-component {
      &__confirm--join-loyalty {
        display: block;
      }
    }
  }
  @media #{$medium-up} {
    .field[type='text'],
    .field[type='email'],
    .field[type='password'],
    .field[type='tel'],
    .form-text,
    .form-submit {
      width: $sign-in-overlay-form-width;
    }
  }
  &__registration-options {
    .info-gdpr-right-icon {
      @media #{$small-only} {
        float: none;
        margin-#{$ldirection}: 0;
      }
    }
  }
  &__confirm--join-loyalty {
    .signin-loyalty-cta {
      @include swap_direction(margin, 90px 25px 0 25px);
      @media #{$medium-up} {
        @include swap_direction(margin, 75px 0 0 0);
        @include swap_direction(padding, 0);
      }
      &__header {
        font-family: $optimalight;
        font-size: 40px;
        line-height: 42px;
        letter-spacing: initial;
      }
      &__sub-header {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.15em;
        margin-top: 2px;
      }
      &__text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15em;
        margin-top: 20px;
        text-transform: uppercase;
      }
      &__button {
        margin-top: 20px;
        width: 270px;
      }
      &__decline {
        margin-top: 12px;
      }
      &__link {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.15em;
        color: $color-navy;
        text-decoration: underline;
      }
      &__terms {
        font-size: 12px;
        margin-top: 26px;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  &__terms {
    .error {
      .terms-privacy-link {
        color: $color-red;
      }
    }
  }
}

.device-mobile {
  #colorbox {
    &.signin-overlay-wrapper {
      #cboxContent {
        height: 100% !important;
        padding-top: 80px;
      }
    }
    .new-user-registration {
      background: none;
      #cboxWrapper {
        #cboxContent {
          background: none;
          .sign-in-component {
            &__confirm-registration-fields {
              border: none;
            }
          }
          .sign-in-component--loyalty {
            .sign-in-component__form-loyalty {
              display: none;
            }
          }
        }
      }
    }
  }
}

.sign-in-page {
  min-height: auto;
  @media #{$medium-up} {
    @include min-height(600px);
  }
}

.signin-overlay-loyalty {
  #cboxContent {
    @media #{$medium-up} {
      @include swap_direction(padding, 0);
    }
  }
  @media #{$medium-up} {
    .sign-in-component--loyalty {
      padding-top: 70px;
    }
    .registration-confirmation {
      background: none;
      .sign-in-component__header {
        font-size: 30px;
        letter-spacing: initial;
      }
    }
  }
  @media #{$device-ipad_only} {
    .sign-in-component--loyalty {
      padding-top: 10px;
    }
  }
  &.new-user-registration {
    @media #{$medium-up} {
      #cboxContent {
        background: $color-white;
      }
    }
  }
  #cboxLoadedContent {
    .sign-in-component__form {
      &-content {
        width: 90%;
      }
      &--registration {
        overflow: hidden;
      }
      &--sign-in {
        height: auto;
      }
    }
  }
}

.sign-in-page--loyalty {
  @media #{$medium-up} {
    margin-top: -82px;
    &-container {
      .sign-in-component--loyalty {
        @include swap_direction(margin, 0 auto);
        background: none;
        width: 950px;
      }
    }
  }
}

.sign-in-component--loyalty {
  @include swap_direction(padding, 0 0 40px 0);
  @media #{$medium-up} {
    @include clearfix;
    @include swap_direction(padding, 162px 0 0 0);
    width: 100%;
    height: 630px;
  }
  .sign-in-component {
    &__header {
      font-size: 40px;
      line-height: 42px;
      letter-spacing: initial;
      color: $color-navy;
    }
    &__form-content--sign-in {
      width: 90%;
    }
  }
  .sign-in-component__form {
    &--registration,
    &--sign-in {
      @include swap_direction(margin, 0);
      width: 100%;
    }
    &-loyalty {
      display: none;
      @media #{$medium-up} {
        @include swap_direction(padding, 0 78px 0 92px);
        display: block;
        float: $ldirection;
        width: 56%;
        color: $color-navy;
        height: 520px;
      }
      &-image {
        width: 100%;
        @media #{$device-ipad_only} {
          margin-top: 100px;
        }
      }
      &-header {
        @include swap_direction(margin, 20px 0 0 5px);
        font-family: $optimalight;
        font-size: 40px;
        line-height: 42px;
        letter-spacing: initial;
      }
      &-text {
        font-size: 17px;
        letter-spacing: 0.05em;
        text-align: $ldirection;
        margin-#{$ldirection}: 6px;
        p {
          @include swap_direction(margin, 15px 0);
          line-height: normal;
        }
        a {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.15em;
          color: $color-navy;
        }
      }
    }
    &-content {
      @media #{$medium-up} {
        color: $color-navy;
        float: $rdirection;
      }
      &--sign-in {
        margin-top: 40px;
      }
      .signin-loyalty-cta__acceptance {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* optional */
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        /* reorder elements so CTA is on top for this site */
        .text--checkbox-label {
          -webkit-box-ordinal-group: 2;
          -moz-box-ordinal-group: 2;
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
          margin-top: 15px;
        }
        .signin-loyalty-cta__button {
          -webkit-box-ordinal-group: 1;
          -moz-box-ordinal-group: 1;
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;
        }
      }
    }
    @media #{$device-ipad_only} {
      &-content {
        padding-#{$ldirection}: 0 !important;
        margin-top: 80px;
      }
    }
    .form-submit {
      @media #{$medium-up} {
        width: 270px;
      }
    }
    &-loyalty-link {
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15em;
      color: $color-navy;
    }
  }
  @media #{$medium-up} {
    .sign-in-component__form {
      &-content {
        padding-#{$ldirection}: 25px;
        .sign-in-component__header {
          width: 300px;
          font-size: 40px;
          line-height: 40px;
          margin-bottom: 20px;
          position: relative;
          bottom: 6px;
        }
        .form-item {
          margin-bottom: 12px;
        }
        .sign-in-component__password-wrapper {
          margin-bottom: 26px;
          overflow: visible;
        }
        .sign-in-component__registration-options {
          width: 335px;
          @media #{$medium-up} {
            float: $ldirection;
            margin-top: 0;
          }
          .info-gdpr {
            &-for-left,
            &-right-icon {
              @media #{$medium-up} {
                display: inline-block;
              }
            }
          }
          .sign-in-component {
            &__loyalty,
            &__terms {
              &.text--form-help {
                label {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
        .form-submit {
          margin-bottom: 15px;
        }
      }
      &-loyalty {
        @include swap_direction(padding, 60px 20px 0 81px);
        height: 530px;
        .sign-in-component {
          &__form-loyalty-header {
            @include swap_direction(margin, 13px 0 0);
            letter-spacing: initial;
            word-spacing: 1px;
            line-height: 40px;
          }
          &__form-loyalty-text {
            p {
              @include swap_direction(margin, 17px 0);
              line-height: 19px;
              position: relative;
              #{$rdirection}: 7px;
            }
          }
        }
      }
    }
  }
  @media #{$device-ipad_only} {
    .sign-in-component__form {
      &-loyalty {
        @include swap_direction(padding, 10px 15px 0 25px);
      }
    }
  }
}

#signin {
  @media #{$medium-up} {
    .sign-in-component__form-loyalty {
      padding-left: 40px;
    }
  }
}

.loyalty_popover {
  @media #{$medium-up} {
    @include swap_direction(padding, 0);
    &.valid_unused {
      .button {
        width: auto;
      }
    }
  }
  &__status {
    a {
      display: none;
    }
  }
  .loyalty_popover {
    &__checkbox-container {
      .loyalty_popover__checkbox-label {
        padding-#{$ldirection}: 25px;
      }
    }
  }
  &__sub-header {
    @media #{$medium-up} {
      font-size: 34px;
    }
  }
}

.loyalty_mrkt_ldng {
  .loyalty_join_signin {
    @include swap_direction(margin, 50px 0 39px 0);
    .not_loyalty_join_now {
      @include swap_direction(margin, 0 20%);
      display: block;
    }
  }
  .error,
  .text--checkbox-label {
    text-align: $ldirection;
    line-height: 18px;
  }
}

.my-feed-tray--loyalty {
  .description-subheader {
    li {
      list-style: square inside;
    }
  }
  .my-feed-tray__col {
    &.special-offer {
      .page-sticky-footer {
        .drawer-formatter__content & {
          @include swap_direction(padding, 0 20px 0 0);
          @include swap_direction(margin, 0);
        }
      }
      .special-offer__container {
        .special-offer__header {
          font-size: 16px;
        }
        .special-offer__description-container {
          span {
            font-family: $akzidenz;
          }
          span.description-headline {
            font-size: 15px !important;
            font-weight: bold;
            margin-top: 5px;
          }
          span.description-subheader {
            font-size: 10px !important;
          }
        }
      }
    }
  }
  .loyalty-offer {
    max-width: 500px;
  }
}

.page-utilities {
  &__account-button {
    .user-login-state {
      @media #{$medium-only} {
        #{$rdirection}: 17px;
      }
    }
  }
}

#loyalty__content {
  .e-list_signed {
    .chart {
      display: block;
    }
  }
}

@media #{$large-up} {
  #loyalty__panel__offers {
    &__offer-image-container {
      .checkout__offer-code__wallet-item__image__title {
        font-size: 15px !important;
      }
    }
  }
}

#colorbox.signin-overlay-wrapper.signin-overlay-loyalty {
  width: 700px !important;
  height: 630px !important;
  margin-left: 161px;
  @media #{$medium-down} {
    width: 100% !important;
    height: 630px !important;
    margin-left: 0;
  }
  #cboxWrapper {
    width: 700px !important;
    height: 630px !important;
    @media #{$medium-down} {
      width: 100% !important;
      height: 630px !important;
    }
    #cboxContent {
      width: 700px !important;
      height: 630px !important;
      background: $color-white;
      @media #{$medium-down} {
        width: 100% !important;
        height: 630px !important;
        padding: 10px;
      }
      #cboxLoadedContent {
        width: 700px !important;
        height: 630px !important;
        @media #{$medium-down} {
          width: 100% !important;
          height: 630px !important;
        }
        .sign-in-component {
          &__form-loyalty {
            display: none;
          }
          &--loyalty {
            overflow: overlay;
          }
          &__form {
            &--sign-in {
              height: auto;
              &--loyalty {
                overflow: overlay;
              }
            }
            &-content {
              width: 90%;
              &--sign-in {
                width: 90%;
              }
            }
          }
          .social-login.gnav {
            margin-right: 0;
          }
          #loyalty_registration_form {
            overflow: hidden;
            height: auto;
          }
          input[type='checkbox'] ~ label {
            @media #{$medium-up} {
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}

@media #{$medium-up} {
  #signin {
    .password-field__info {
      right: 15%;
      top: 37px;
      width: 50%;
    }
    .password-field__info::before {
      display: none;
    }
  }
  .sign-in-component {
    .sign-in-component__confirm--registration {
      h4.sign-in-component__header {
        padding: 0;
      }
    }
  }
}
